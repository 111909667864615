export enum Background {
  Calming = 'Calming',
  Divine = 'Divine',
  India = 'India',
  Jump = 'Jump',
  Natures = 'Natures',
  NewAge = 'NewAge',
  Northern = 'Northern',
  Pale = 'Pale',
  Spacey = 'Spacey',
  Binaural = 'Binaural'
}
export enum VoiceSpeed {
  Mellow = 0.75,
  Relaxed = 0.85,
  Moderate = 1.0,
  Lively = 1.15,
  Brisk = 1.3
}

export enum VoiceSelector {
  Mellow = 'Mellow',
  Relaxed = 'Relaxed',
  Moderate = 'Moderate',
  Lively = 'Lively',
  Brisk = 'Brisk'
}

export enum Voices {
  iAffirm = 'iAffirm',
  Benajmin = 'Benajmin',
  Kaine = 'Kaine',
  Adam = 'Adam',
  Francesca = 'Francesca',
  Ana = 'Ana',
  Jaxon = 'Jaxon',
  Jack = 'Jack',
  Serenity = 'Serenity',
  Brittney = 'Brittney',
  Danielle = 'Danielle'
}
